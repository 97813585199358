.rtllabel{
    display: flex;
    justify-content: right;
}
.ltrlabel{
    display: flex;
    justify-content: left;
}
.Avatar{
    margin:1;
    background-color: #dc143c;
}
.submitbtn{
    margin:3 0 2;
    color: #0d6efd;
}
.imgsize{
width: 100px;
height: 100px;
}
.checkboxcenter{
    display: flex;
    justify-content: center;
}
.margincheck{
   margin-right: 25px;
}