/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}
body{
  font-family: Arial, Helvetica, sans-serif;
}
